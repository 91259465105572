import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { auth, signUp , signIn, db} from "../../firebaseConfig";
import { useNavigate } from 'react-router-dom';
import { set, ref} from "firebase/database";



function Copyright(props) { 
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp() {
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(null);

    const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    
    if (email === "") {
      setErrorMsg("Missing email");
      setError(true);
    } else if (password === "") {
      setErrorMsg("Missing password");
      setError(true);
    } else{
      signUp(email, password)
    .then((userCredential) => {
        signIn(email, password).then(() => {
        // const user = userCredential.user;
        let user = auth.currentUser;
        set(ref(db, 'users/' + user.uid), {
          email: email,
        });
        navigate("/")
      }).catch((error) => {
        const errorCode = error.code;
        console.log(errorCode)
      });
    })
    .catch((error) => {
      const errorCode = error.code;
      //console.log(errorCode)
      var fill = errorCode.substr(5)
      var fill2 = fill.replaceAll("-", " ")
      var first = fill.substr(0,1)
      setErrorMsg(first.toUpperCase() + fill2.substr(1))
      const errorMessage = error.message;
      setError(true);
      // ..
    });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          {error ? <Typography color="red">{errorMsg}</Typography> : <div></div>}
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onclick="register()"
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
              <Link
                variant="body2"
                href="#/login"
              >
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}