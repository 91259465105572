import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles.css"

import App from './App';

import { BrowserRouter, HashRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    //<HashRouter>
    <HashRouter>
        <App/>
    </HashRouter>
    //</HashRouter>
);
