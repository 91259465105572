import React, {useState, useEffect} from "react";
import Box from '@mui/material/Box';
import QuestionCard from '../../QuestionCard';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import CodeSnippet from '../../CodeSnippet';
import ".//courses.css";
import { db  } from "../../../firebaseConfig";
import {onValue, ref } from "firebase/database";
 
export default function Booleans(authUser) {
    // const BooleanQ = questionsSolved.Booleans;
    // const q1 = BooleanQ.one;
    // const q2 = BooleanQ.two;

    const [question1, setQuestion1] = useState(0);
    const [question2, setQuestion2] = useState(0);

    

    useEffect(() => {
        // console.log(authUser.uid);
        if (localStorage.Booleansquestion1) {
            setQuestion1(1);
        }
        if (localStorage.Booleansquestion2) {
            setQuestion2(1);
        }
        if (authUser.authUser !== null) {
            onValue(ref(db, `users/${authUser.uid}/questionsSolved/Booleans/1/solved`), (snapshot) => {
                if (snapshot.exists()) {
                    localStorage.setItem("Booleansquestion1", 1);
                    setQuestion1(1);
                }});
             onValue(ref(db, `users/${authUser.uid}/questionsSolved/Booleans/2/solved`), (snapshot) => {
                if (snapshot.exists()) {
                    localStorage.setItem("Booleansquestion2", 1);
                    setQuestion2(1);
                }});
        } 
    }, []);
    
    
    
    // if (questionsSolved.Booleans !== null) {
    //     let BooleanQ = questionsSolved.Booleans;
    //     if (BooleanQ.one !== null) {
    //         setQuestion1(1);
    //     }
    //     if (BooleanQ.two !== null) {
    //         setQuestion2(1);
    //     }
    // }

    // const [question1, setQuestion1] = useState({q1.solved === null ? q1.solved : 0});
    // const [question2, setQuestion2] = useState({q2.solved === null ? q2.solved : 0});

    
    return (
        <>
            <Typography variant = "h2">Booleans</Typography>
                <br></br>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4" gutterBottom>Booleans are a data type that hold either True or False</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "booleanVar1 = True"
                    },{
                        line: 2,
                        code: "booleanVar2 = False"
                    },{
                        line: 3,
                        code: "print(type(booleanVar1))"
                    },{
                        line: 4,
                        code: "print(type(booleanVar2))"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "<class 'bool'>"
                    },{
                        line: 2,
                        code: "<class 'bool'>"
                    },]} content = "left"/>
                    <Typography variant = "h6"gutterBottom>bool is the python name for the Boolean data type</Typography>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4"gutterBottom>Comparative operators</Typography>
                    <Typography variant = "h6"gutterBottom>Other than just writing True or False, we can get Boolean values using comparative operators</Typography>
                    <Typography variant = "h6"gutterBottom>Any expression using theses comparative operators are called Boolean expressions</Typography>
                    <Typography variant = "h6"gutterBottom>Examples of comparative operators include:</Typography>
                    <Typography variant = "h6"gutterBottom>
                        <ul>
                            <li>{'>'}</li>
                            <li>{'<'}</li>
                            <li>{'>='}</li>
                            <li>{'<='}</li>
                            <li>{'=='}</li>
                            <li>{'!='}</li>
                        </ul>
                    </Typography>
                    <Typography variant = "h6"gutterBottom>These operators are the same as the mathematical symbols</Typography>
                    <Typography variant = "h6"gutterBottom>{'>'} means greater than, {'>='} means greater than or equal to, {'=='} means equal to, and {'!='} means no equal to</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(5 > 4, end = ' ')"
                    },{
                        line: 2,
                        code: "print(4 < 5, end = ' ')"
                    },{
                        line: 3,
                        code: "print(5 >= 4.5, end = ' ')"
                    },{
                        line: 4,
                        code: "print(3 <= 3, end = ' ')"
                    },{
                        line: 5,
                        code: "print(3 == 3, end = ' ')"
                    },{
                        line: 6,
                        code: "print(3 != 5)"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "True True True True True True"
                    },]} content = "left"/>
                    <Typography variant = "h6">Same thing with the less than operator, {'<'}</Typography>
                    <Typography variant = "h6"gutterBottom>These operators check if two objects have a certain relationship</Typography>
                    <Typography variant = "h6"gutterBottom>Take the expression <b>5 {'>'} 3</b> for example</Typography>
                    <Typography variant = "h6"gutterBottom>In this case, <b>{'>'}</b> would be the comparative operator,  and <b>5</b> and <b>3</b> would be the objects we are checking</Typography>
                    <Typography variant = "h6"gutterBottom>If we put the expression together, we are checking: is 5 greater than 3?</Typography>
                    <Typography variant = "h6"gutterBottom>Since 5 is greater than 3, then this expression returns the Boolean value of True</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(5 > 3)"
                    },{
                        line: 2,
                        code: "print(type(5 > 3))"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "True"
                    },{
                        line: 2,
                        code: "<class 'bool'>"
                    },]} content = "left"/>
                    <Typography variant = "h6" gutterBottom>If we wrote 3 {'>'} 5, then the expression would return the Boolean value of False because 3 is not greater than 5</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(3 > 5)"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "False"
                    },]} content = "left"/>
                    <Typography variant = "h6">Note: == checks if two objects are equal to each other, while = assigns the value on the right to the variable on the left</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(3 == 3)"
                    },{
                        line: 2,
                        code: "var = 3"
                    },
                    {
                        line: 3,
                        code: "print(var)"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "True"
                    },{
                        line: 2,
                        code: "3"
                    },]} content = "left"/>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Boolean expressions</Typography>
                    <Typography variant = "h6" gutterBottom>When using Boolean expressions, we have to understand that they will all return either True or False</Typography>
                    <Typography variant = "h6">For example, if we wanted to assign a Boolean value to a variable using a Boolean expression:</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "var = 4 <= 6"
                    },{
                        line: 2,
                        code: "print(var)"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "True"
                    }]} content = "left"/>
                    <Typography variant = "h6"gutterBottom>In this case, 4 {'<='} 6 is a Boolean expression because it returns True</Typography>
                    <Typography variant = "h6"gutterBottom>Thus, we can treat it as data, the value of True, that we can assign to a variable</Typography>
                    <Typography variant = "h6"gutterBottom>In order to assign a value to a variable, we put the variable on the left, the equal sign in the middle, and the value on the right</Typography>
                    <Typography variant = "h6">Thus, we get that var = True</Typography>
                    <Typography variant = "h6">The main takeaway is that we can treat Boolean expressions as Boolean values</Typography>
                </Box>
                
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Logical operators</Typography>
                    <Typography variant = "h6" >Logical operators allow us compare two Boolean values to return one Boolean value</Typography>
                    <Typography variant = "h6" gutterBottom>Examples of logical operators include:</Typography>
                    <Typography variant = "h6"gutterBottom>
                        <ul>
                            <li>and</li>
                            <li>or</li>
                        </ul>
                    </Typography>
                    <Typography variant = "h6"gutterBottom>To use a logical operator, we put two Boolean expressions around it:</Typography>
                    <Typography variant = "h6"gutterBottom>Boolean expression + <b>logical operator</b> + Boolean expression</Typography>
                    <Typography variant = "h6"gutterBottom>For example</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "True and False"
                    }]} content = "center"/>
                    <Typography variant = "h6"gutterBottom>The <i>and</i> logical operator only returns True if the two Boolean values around it are both True; otherwise, it returns False</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(True and (6 > 5))"
                    },{
                        line: 2,
                        code: "var = True and (6 <= 5)"
                    },{
                        line: 3,
                        code: "print(var)"
                    },{
                        line: 4,
                        code: "print(False and False)"
                    }]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "True"
                    },{
                        line: 2,
                        code: "False"
                    },{
                        line: 3,
                        code: "False"
                    }]} content = "left"/>
                    <Typography variant = "h6"gutterBottom>The <i>or</i> logical operator returns True if at least one of the values are True; if both are False, then it returns False</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(True and (6 > 5))"
                    },{
                        line: 2,
                        code: "var = (6 <= 5) and False"
                    },{
                        line: 3,
                        code: "print(var)"
                    },{
                        line: 4,
                        code: "print(False and False)"
                    }]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "True"
                    },{
                        line: 2,
                        code: "True"
                    },{
                        line: 3,
                        code: "False"
                    }]} content = "left"/>
                    <Typography variant = "h6"gutterBottom>These expresions allow us to combine Boolean expressions</Typography>
                </Box>

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Reversing Boolean values</Typography>
                    <Typography variant = "h6" gutterBottom>If we wanted to reverse the value of a Boolean expression, we use the <b>not</b> keyword</Typography>
                    <Typography variant = "h6" gutterBottom>We just have to put <i>not</i> in front of the expression we want</Typography>
                    <Typography variant = "h6"gutterBottom>Make sure to wrap the entire expression in parentheses</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(not(True))"
                    },{
                        line: 2,
                        code: "print(not(False))"
                    },{
                        line: 3,
                        code: "print(not((5 <= 4) and (6 == 6)))"
                    },{
                        line: 4,
                        code: "print((3 != 3) or not(True))"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "False"
                    },{
                        line: 2,
                        code: "True"
                    },{
                        line: 3,
                        code: "True"
                    },{
                        line: 4,
                        code: "False"
                    },]} content = "left"/>
                    <Typography variant = "h6"></Typography>
                </Box>

                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>     
                <Typography variant = "h4"gutterBottom>Practice</Typography>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                padding: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#e0e0e0',
                }}>
                <QuestionCard question = {{
                    question: "Which expression sets var to a Boolean expression?",
                }} 
                answers = {{
                    a1: "var = 2 == 3",
                    a2: "2 = 3",
                    a3: "var == 3",
                    a4: "var = 3"
                }}
                answer = {1}
                questionNumber = {1}
                correct = {question1}
                setCorrect = {() => {
                    setQuestion1(1)}}
                course = 'Booleans'
                authUser = {authUser}
                />
                </Box>
                

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>

                <Box sx={{
                padding: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#e0e0e0',
                }}>
                    <QuestionCard question = {{
                    question: "Which expression returns True?",
                }} 
                answers = {{
                    a1: "6 > 10",
                    a2: "not(5 <= 7)",
                    a3: "(6 != 4) and (4 == 6)",
                    a4: "not(3 > 3) or (2 < 1)"
                }}
                answer = {4}
                questionNumber = {2}
                correct = {question2}
                setCorrect = {() => {
                    setQuestion2(1)}}
                course = 'Booleans'
                authUser = {authUser}
                />
                </Box>

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>

                <LinearProgress color = "success" variant = 'determinate' value = {(((question1 + question2) /2) * 100)}/>
                { (((question1 + question2) /2) * 100) === 100 ? 
                <Typography variant = "h5">Finished!</Typography> :  <Typography variant = "h5">Not done yet</Typography>}
        </>
    )
        
};
