import {React, useState} from "react";
import Button from '@mui/material/Button';
import {HashRouter, Route, Routes} from "react-router-dom";
import {Link} from 'react-router-dom';
import WICoding from "./courses/WICoding";
import ForLoops from "./courses/ForLoops";
import YPython from "./courses/YPython";
import Box from '@mui/material/Box';
import IfStatements from "./courses/IfStatements";
import Variables from "./courses/Variables";
import DataTypes from "./courses/DataTypes";
import Booleans from "./courses/Booleans";
import Lists from "./courses/Lists";
import {auth} from "../../firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";


export default function Courses(params) {
    
    const [authUser, setAuthUser] = useState(null);

    onAuthStateChanged(auth, (user) => {
        if (user !== null) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            user = auth.currentUser;
            setAuthUser(user);
            // console.log(authUser);
            // ...
        } else {
            // User is signed out
            // ...

        }
    });
    
    return (
        <>
            <Routes>
                <Route path = '/whatIsCoding' element = {<WICoding authUser = {authUser} setAuthUser = {setAuthUser}/>} />
                <Route path = '/whyPython' element = {<YPython authUser = {authUser} setAuthUser = {setAuthUser}/>} />
                <Route path = '/forLoops' element = {<ForLoops authUser = {authUser} setAuthUser = {setAuthUser}/>} />
                <Route path = '/ifStatements' element = {<IfStatements authUser = {authUser} setAuthUser = {setAuthUser}/>} />
                <Route path = '/variables' element = {<Variables authUser = {authUser} setAuthUser = {setAuthUser}/>} />
                <Route path = '/dataTypes' element = {<DataTypes authUser = {authUser} setAuthUser = {setAuthUser}/>} />
                <Route path = '/booleans' element = {<Booleans authUser = {authUser} setAuthUser = {setAuthUser}/>} />
                <Route path = '/lists' element = {<Lists authUser = {authUser} setAuthUser = {setAuthUser}/>} />
            </Routes>

            <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
            <h1>Courses</h1>
            <div>

                <div>
                    
                    <Button variant="contained" color="primary" component={Link} to='/courses/whatIsCoding' sx={{
                    fontSize: 20, 
                    margin: 3,
                    }}>What Is Coding?</Button>

                    <Button variant="contained" color="primary" component={Link} to='/courses/whyPython' sx={{
                    fontSize: 20, 
                    margin: 3,
                    }}>Why Python?</Button>

                    <Button variant="contained" color="primary" component={Link} to='/courses/dataTypes' sx={{
                    fontSize: 20, 
                    margin: 3,
                    }}>Data Types</Button>

                    <Button variant="contained" color="primary" component={Link} to='/courses/variables' sx={{
                    fontSize: 20, 
                    margin: 3,
                    }}>Variables</Button>

                    <Button variant="contained" color="primary" component={Link} to='/courses/booleans' sx={{
                    fontSize: 20, 
                    margin: 3,
                    }}>Booleans</Button>

                    <Button variant="contained" color="primary" component={Link} to='/courses/ifStatements' sx={{
                    fontSize: 20, 
                    margin: 3,
                    }}>If Statements</Button>

                    <Button variant="contained" color="primary" component={Link} to='/courses/lists' sx={{
                    fontSize: 20, 
                    margin: 3,
                    }}>Lists</Button>

                    <Button variant="contained" color="primary" component={Link} to='/courses/forLoops' sx={{
                    fontSize: 20, 
                    margin: 3,
                    }}>For Loops</Button>
                </div>

            </div>
            
        </>
    )
};
