import React, {useState, useEffect} from "react";
import Box from '@mui/material/Box';
import QuestionCard from '../../QuestionCard';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import CodeSnippet from '../../CodeSnippet';
import { db  } from "../../../firebaseConfig";
import {onValue, ref } from "firebase/database";
 

export default function Variables(authUser) {
    const [question1, setQuestion1] = useState(0);
    const [question2, setQuestion2] = useState(0);

    useEffect(() => {
        // console.log(authUser.uid);
        if (localStorage.Variablesquestion1) {
            setQuestion1(1);
        }
        if (localStorage.Variablesquestion2) {
            setQuestion2(1);
        }
        if (authUser.authUser !== null) {
            onValue(ref(db, `users/${authUser.uid}/questionsSolved/Variables/1/solved`), (snapshot) => {
                if (snapshot.exists()) {
                    localStorage.setItem("Variablesquestion1", 1);
                    setQuestion1(1);
                }});
             onValue(ref(db, `users/${authUser.uid}/questionsSolved/Variables/2/solved`), (snapshot) => {
                if (snapshot.exists()) {
                    localStorage.setItem("Variablesquestion2", 1);
                    setQuestion2(1);
                }});
        } 
    }, []);
    
    return (
        <>
            <Typography variant = "h2">Variables</Typography>
                <br></br>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4" gutterBottom>Variables allow us to give names to data</Typography>
                    <Typography variant = "h6" gutterBottom>We can change the value that a variable represents</Typography>
                    <Typography variant = "h6">We can also use the variable names to reference the data</Typography>
                    
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4"gutterBottom>Variable Syntax</Typography>
                    <Typography variant = "h6">A variable in python holds a value of any type: String, Boolean, int, etc.</Typography>
                    <Typography variant = "h6">Instead of writing the value every time, we can use the variable name to substitute for it</Typography>
                    <Typography variant = "h6"gutterBottom>To make a variable, we first have to give the variable name, then an equal sign, then the value the variable holds</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "variableName = value"
                    },]} content = "center"/>
                    <Typography variant = "h6" gutterBottom>You always need an equal sign between the value and the variable</Typography>
                    <Typography variant = "h6">Unlike math, this equal sign tells the computer to assign the value on the right to the value on the left</Typography>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Using variables</Typography>
                    <Typography variant = "h6" gutterBottom>Using variables is as simple as using their name where you want the value they represent to be</Typography>
                    <Typography variant = "h6">First, you have to create the variable by providing the name and value and assigning the value to the variable</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "var1 = 1"
                    },{
                        line: 2,
                        code: "var2 = 2"
                    }
                    ,]} content = "center"/>
                    <Typography variant = "h6"gutterBottom>Then, you call the variable name in place of where you want to use the value</Typography>
                    <Typography variant = "h6">The method of using variables is similar to using variables in math</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(var1 + var2)"
                    }]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "3"
                    }]} content = "left"/>
                    <Typography variant = "h6">Note: Variable names are case sensitive</Typography>
                    <Typography variant = "h6"gutterBottom>In this case, var1 was equal to 1, and var2 was equal to 2</Typography>
                    <Typography variant = "h6" gutterBottom>By calling them in the print statement, we print out the sum of var1 and var2</Typography>
                    <Typography variant = "h6">var1 + var2 = 1 + 2 = 3</Typography>
                </Box>
                
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Assigning variables to variables</Typography>
                    <Typography variant = "h6" gutterBottom>As we saw before, we can assign variables by using the variableName + <b>=</b> + value expression</Typography>
                    <Typography variant = "h6" gutterBottom>We can also use other variables as values to assign to another variables</Typography>
                    <Typography variant = "h6">This is possible because variables are supposed to represent values in the first place, so using them in place of where a value should go is how they're meant to be used</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "var1 = 1"
                    },{
                        line: 2,
                        code: "var2 = var1"
                    }
                    ,]} content = "center"/>
                    <Typography variant = "h6"gutterBottom>In this case, we assigned the value of 1 to var1</Typography>
                    <Typography variant = "h6"gutterBottom>In the next line, we assigned the value of var1 to var2</Typography>
                    <Typography variant = "h6"gutterBottom>Since var1 is equal to 1, then var2 is also then equal to 1</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(var1 + var2)"
                    }]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "2"
                    }]} content = "left"/>
                    <Typography variant = "h6"gutterBottom>Note: In some other languages, when you assign one variable to another, the second variable will then be equal to the value of the first variable</Typography>
                    <Typography variant = "h6"gutterBottom>For example, in our case, then var2 would be equal to var1, and changing var1 would change var2</Typography>
                    <Typography variant = "h6">This doesn't happen in Python, though</Typography>
                </Box>

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Creating multiple variables at once</Typography>
                    <Typography variant = "h6" gutterBottom>We can create multiple variables and/or change variable values in one line</Typography>
                    <Typography variant = "h6" gutterBottom>We just have to use commas to separate the variable names and respective values</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "var1, var2, var3 = 1, 2, 3"
                    },{
                        line: 2,
                        code: "print(var1)"
                    },{
                        line: 3,
                        code: "print(var2)"
                    },{
                        line: 4,
                        code: "print(var3)"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "1"
                    },{
                        line: 2,
                        code: "2"
                    },{
                        line: 3,
                        code: "3"
                    },]} content = "left"/>
                    <Typography variant = "h6">Note: There are other ways to assign multiple variables at a time, you can look them up if you want to</Typography>
                </Box>

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Updating variable values</Typography>
                    <Typography variant = "h6" gutterBottom>After the inital assignment of a value to a variable, we can keep changing the value of the variable using the same expression</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "var = 'hello'"
                    },{
                        line: 2,
                        code: "print(var)"
                    },{
                        line: 3,
                        code: "var = 'world'"
                    },{
                        line: 4,
                        code: "print(var)"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "hello"
                    },{
                        line: 2,
                        code: "world"
                    }]} content = "left"/>
                    <Typography variant = "h6" gutterBottom>The values don't have to be of the same type either</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "var = 'hi'"
                    },{
                        line: 2,
                        code: "print(var)"
                    },{
                        line: 3,
                        code: "var = 1"
                    },{
                        line: 4,
                        code: "print(var)"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "hi"
                    },{
                        line: 2,
                        code: "1"
                    }]} content = "left"/>
                </Box>

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Variable casting</Typography>
                    <Typography variant = "h6" gutterBottom>We can temporarily change the type of a variable by casting it to another</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "var = '3'"
                    },{
                        line: 2,
                        code: "print(var1, 'this is a string')"
                    },{
                        line: 3,
                        code: "print(int(var), 'this is an int')"
                    }]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "3, this is a string"
                    },{
                        line: 2,
                        code: "3, this is an int"
                    }]} content = "left"/>
                    <Typography variant = "h6">Note: Casting 'three' to 3 will not work</Typography>
                </Box>

                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>     
                <Typography variant = "h4"gutterBottom>Practice</Typography>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                padding: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#e0e0e0',
                }}>
                <QuestionCard question = {{
                    question: "Which expression assigns the value of 1 to hello?",
                }} 
                answers = {{
                    a1: "1 = hello",
                    a2: "hello = '1'",
                    a3: "hello is 1",
                    a4: "hello = 1"
                }}
                answer = {4}
                questionNumber = {1}
                correct = {question1}
                setCorrect = {() => setQuestion1(1)}
                course = 'Variables'
                authUser = {authUser}/>
                <p></p>
                </Box>
                

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>

                <Box sx={{
                padding: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#e0e0e0',
                }}>
                    <QuestionCard question = {{
                    question: "Which expression correctly assigns the value of a to b?",
                }} 
                answers = {{
                    a1: <>
                    <Box sx = {{
                        bgcolor: "#f0f0f0",
                        padding: 1,
                        borderRadius: 2
                    }}>
                        <Typography>a = 2</Typography>
                        <Typography>a = b</Typography>
                    </Box>
                    </>,
                    a2: <>
                    <Box sx = {{
                        bgcolor: "#f0f0f0",
                        padding: 1,
                        borderRadius: 2
                    }}>
                        <Typography>a = 2</Typography>
                        <Typography>b = 2</Typography>
                    </Box>
                    </>,
                    a3: <>
                    <Box sx = {{
                        bgcolor: "#f0f0f0",
                        padding: 1,
                        borderRadius: 2
                    }}>
                        <Typography>a = 2</Typography>
                        <Typography>b = a</Typography>
                    </Box>
                    </>,
                    a4: <>
                    <Box sx = {{
                        bgcolor: "#f0f0f0",
                        padding: 1,
                        borderRadius: 2
                    }}>
                        <Typography>a = 2</Typography>
                        <Typography>2 = b</Typography>
                    </Box>
                    </>
                }}
                answer = {3}
                questionNumber = {2}
                correct = {question2}
                setCorrect = {() => setQuestion2(1)}
                course = 'Variables'
                authUser = {authUser}
                />
                </Box>

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>

                <LinearProgress color = "success" variant = 'determinate' value = {(((question1 + question2) /2) * 100)}/>
                { (((question1 + question2) /2) * 100) === 100 ? 
                <Typography variant = "h5">Finished!</Typography> :  <Typography variant = "h5">Not done yet</Typography>}
        </>
    )
        
};
