import React, {useState } from "react";
import Box from '@mui/material/Box';
import { createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export default function Home(params) {
    return (
        <>
        <Typography variant = "h2">Learn Python</Typography>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>   
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4" gutterBottom>Welcome to Learn Python</Typography>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4"gutterBottom>Mission</Typography>
                    <Typography variant = "h5"gutterBottom>To teach Python to anyone willing to learn</Typography>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4"gutterBottom>Courses</Typography>
                    <Typography variant = "h6"gutterBottom>There are multiple courses, each going over a basic Python concept</Typography>
                    <Typography variant = "h6"gutterBottom>Once you finish every course, you will have a solid understanding of the fundamentals of Python</Typography>
                    <br></br>
                    <Typography variant = "h6"gutterBottom>Each course has two sections: the Information and the Quiz</Typography>
                    <Typography variant = "h6"gutterBottom>The Quiz will allow you to keep submitting until you get the question right</Typography>
                    <Typography variant = "h6" fontWeight='Bold' gutterBottom>You can save your progress by making an account</Typography>
                </Box>
        </>
    )
};
