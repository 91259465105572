import React, {useState, useEffect} from "react";
import Box from '@mui/material/Box';
import QuestionCard from '../../QuestionCard';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import CodeSnippet from '../../CodeSnippet';
import ".//courses.css";
import { db  } from "../../../firebaseConfig";
import {onValue, ref } from "firebase/database";
 

export default function Lists(authUser) {
    const [question1, setQuestion1] = useState(0);
    const [question2, setQuestion2] = useState(0);
    const [question3, setQuestion3] = useState(0);
    const [question4, setQuestion4] = useState(0);
    const [question5, setQuestion5] = useState(0);
    const [question6, setQuestion6] = useState(0);

    useEffect(() => {
        // console.log(authUser.uid);
        if (localStorage.Listsquestion1) {
            setQuestion1(1);
        }
        if (localStorage.Listsquestion2) {
            setQuestion2(1);
        }
        if (localStorage.Listsquestion3) {
            setQuestion3(1);
        }
        if (localStorage.Listsquestion4) {
            setQuestion4(1);
        }
        if (localStorage.Listsquestion5) {
            setQuestion5(1);
        }
        if (localStorage.Listsquestion6) {
            setQuestion6(1);
        }
        if (authUser.authUser !== null) {
            onValue(ref(db, `users/${authUser.uid}/questionsSolved/Lists/1/solved`), (snapshot) => {
                if (snapshot.exists()) {
                    localStorage.setItem("Listsquestion1", 1);
                    setQuestion1(1);
                }});
             onValue(ref(db, `users/${authUser.uid}/questionsSolved/Lists/2/solved`), (snapshot) => {
                if (snapshot.exists()) {
                    localStorage.setItem("Listsquestion2", 1);
                    setQuestion2(1);
                }});
                onValue(ref(db, `users/${authUser.uid}/questionsSolved/Lists/3/solved`), (snapshot) => {
                if (snapshot.exists()) {
                    localStorage.setItem("Listsquestion3", 1);
                    setQuestion3(1);
                }});
                onValue(ref(db, `users/${authUser.uid}/questionsSolved/Lists/4/solved`), (snapshot) => {
                if (snapshot.exists()) {
                    localStorage.setItem("Listsquestion4", 1);
                    setQuestion4(1);
                }});
                onValue(ref(db, `users/${authUser.uid}/questionsSolved/Lists/5/solved`), (snapshot) => {
                    if (snapshot.exists()) {
                        localStorage.setItem("Listsquestion5", 1);
                        setQuestion5(1);
                    }});
                    onValue(ref(db, `users/${authUser.uid}/questionsSolved/Lists/6/solved`), (snapshot) => {
                    if (snapshot.exists()) {
                        localStorage.setItem("Listsquestion6", 1);
                        setQuestion6(1);
                    }});
        } 
    }, []);
    return (
        <>
            <Typography variant = "h2">Lists</Typography>
                <br></br>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4" gutterBottom>Lists are a data type that hold multiple pieces of data in one place</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "list = [1,2,3,4,5,6]"
                    },{
                        line: 2,
                        code: "list2 = ['hi','hello','bye','goodbye']"
                    }]} content = "center"/>
                    <Typography variant = "h6"gutterBottom>The actual list is the expression on the right with brackets: [1,2,3,4,5,6] and ['hi','hello','bye','goodbye'] in our examples</Typography>
                    <Typography variant = "h6"gutterBottom>The pieces of data, or items, in the list are 1,2,3,4,5, and 6</Typography>
                    <Typography variant = "h6"gutterBottom>Each item in a list is separated by commas</Typography>
                    <Typography variant = "h6"gutterBottom>a list in python can hold basically any data type, from ints to Strings to even other lists</Typography>
                    <Typography variant = "h6"gutterBottom>A list can also hold different data types at once</Typography>
                    <Typography variant = "h6"gutterBottom>Lists are great because we can store many things in one place</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "list = [1,'hi',True,[1,2,3]]"
                    }]} content = "center"/>
                    <Typography variant = "h6"gutterBottom>Note: Lists in some other languages won't let you hold different data types in one list</Typography>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4"gutterBottom>Indexes</Typography>
                    <Typography variant = "h6"gutterBottom>Each item in a list is paried with an index</Typography>
                    <Typography variant = "h6"gutterBottom>The indexes are basically the order of the items in the list</Typography>
                    <Typography variant = "h6"gutterBottom>However, instead of the indexes starting from 1 for the first item, the indexes start at 0 for the first item</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "list = [1,2,3,4,5,6]"
                    },{
                        line: 2,
                        code: "indexes = [0,1,2,3,4,5]"
                    }]} content = "center"/>
                    <Typography variant = "h6"gutterBottom>The indexes aren't shown in the list itself, but they exist in the computer's memory</Typography>
                    <Typography variant = "h6"gutterBottom>In this example, we just made another list to represent the indexes of the first list</Typography>
                    <Typography variant = "h6"gutterBottom>The index of the item 1 in our list is 0. The index of 2, is 1. The index of 3 is 2... The index of 6 is 5.</Typography>
                    <Typography variant = "h6"gutterBottom>Indexes are always in order</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "list = ['hi', 'hello', 'bye', 'goodbye']"
                    },{
                        line: 2,
                        code: "indexes = [0,1,2,3]"
                    }]} content = "center"/>
                    <Typography variant = "h6"gutterBottom>Indexes are always counting numbers starting from 0 from the first item, no matter what data type the items are</Typography>
                    <Typography variant = "h6"gutterBottom>There are only as many indexes as there are items, so there are no indexes less than 0 nor indexes greater than or equal to the amount of items in the list</Typography>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                padding: '5px',
                display: 'grid', 
                gap : 3,
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#e0e0e0',
                }}> 
                <CodeSnippet lines = {[{
                        line: 1,
                        code: "list = [2, 3, 4, 'hi', 6, 7]"
                    }]} content = "center"/>
                <QuestionCard question = {{
                    question: "What is the index of 'hi'?",
                }} 
                answers = {{
                    a1: "5",
                    a2: "4",
                    a3: "3",
                    a4: "2"
                }}
                answer = {3}
                questionNumber = {1}
                correct = {question1}
                setCorrect = {() => setQuestion1(1)}
                course = 'Lists'
                authUser = {authUser}/>
                <p></p>
                </Box>
                
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Retrieving Items from a List</Typography>
                    <Typography variant = "h6" gutterBottom>To retrieve an item from a list, we write the list name, following by the index of the item that we want to get in brackets</Typography>
                    <Typography variant = "h6">(list name)<b>{'['}</b>(index of item)<b>{']'}</b></Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "list = [2,4,6,8,10]"
                    },{
                        line: 2,
                        code: "item = list[3]"
                    },{
                        line: 3,
                        code: "print(item)"
                    }]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "8"
                    }]} content = "left"/>
                    <Typography variant = "h6"gutterBottom>To get the item at the index of 3, we used the listname, list, and put the index we wanted, 3, in brackets after it.</Typography>
                    <Typography variant = "h6"gutterBottom>In this case, 8 has the index of 3 in our list, so 8 is printed</Typography>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                padding: '5px',
                display: 'grid', 
                gap : 3,
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#e0e0e0',
                }}> 
                <CodeSnippet lines = {[{
                        line: 1,
                        code: "list = [2, 'hi', 4, 'hello', 6, 'bye', 8]"
                    }]} content = "center"/>
                <QuestionCard question = {{
                    question: "Which expression returns the item at the 6th index?",
                }} 
                answers = {{
                    a1: "listname[6]",
                    a2: "list['bye']",
                    a3: "list[8]",
                    a4: "list[6]"
                }}
                answer = {4}
                questionNumber = {2}
                correct = {question2}
                setCorrect = {() => setQuestion2(1)}
                course = 'Lists'
                authUser = {authUser}/>
                <p></p>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                padding: '5px',
                display: 'grid', 
                gap : 3,
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#e0e0e0',
                }}> 
                <CodeSnippet lines = {[{
                        line: 1,
                        code: "list = [2, 'hi', 4, 'hello', 6, 'bye', 8]"
                    }]} content = "center"/>
                <QuestionCard question = {{
                    question: "Which expression returns the 4th item?",
                }} 
                answers = {{
                    a1: "list[3]",
                    a2: "list[4]",
                    a3: "list['hello']",
                    a4: "list['6']"
                }}
                answer = {1}
                questionNumber = {3}
                correct = {question3}
                setCorrect = {() => setQuestion3(1)}
                course = 'Lists'
                authUser = {authUser}/>
                <p></p>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Changing List Items</Typography>
                    <Typography variant = "h6" >To change an item in a list, we assign the value we want to the expression used to retrieve an item from the list like we would a variable</Typography>
                    <Typography variant = "h6" gutterBottom>(list name)<b>{'['}</b>(index of item)<b>{']'} = </b> (value)</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "list = [1, 2, 3, 100, 5, 6]"
                    },{
                        line: 2,
                        code: "list[3] = 4"
                    },{
                        line: 3,
                        code: "print(list[3])"
                    }]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "4"
                    }]} content = "left"/>
                    <Typography variant = "h6"gutterBottom>You can think of the expression of (list name)<b>{'['}</b>(index of item)<b>{']'}</b> as a representation of the item of that list at that index</Typography>
                    <Typography variant = "h6"gutterBottom>Thus, you use it both access and change the item at that index</Typography>
                </Box>

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                padding: '5px',
                display: 'grid', 
                gap : 3,
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#e0e0e0',
                }}> 
                <CodeSnippet lines = {[{
                        line: 1,
                        code: "list = [2, 'hi', 4, 'hello', 6, 'bye', 8]"
                    }]} content = "center"/>
                <QuestionCard question = {{
                    question: "which expression changes the item at the index of 5 to 'salutations'?",
                }} 
                answers = {{
                    a1: "list[5] = salutations",
                    a2: "salutations = list[5]",
                    a3: "list['bye] = salutations",
                    a4: "list[6] = 'salutations"
                }}
                answer = {1}
                questionNumber = {4}
                correct = {question4}
                setCorrect = {() => setQuestion4(1)}
                course = 'Lists'
                authUser = {authUser}/>
                <p></p>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Getting the length of a list</Typography>
                    <Typography variant = "h6" gutterBottom>to get the length of a list, we use the len() function</Typography>
                    <Typography variant = "h6" gutterBottom><b>len(</b> (list name) <b>)</b></Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "list = [1,2,3,4,5,6,7]"
                    },{
                        line: 2,
                        code: "length = len(list)"
                    },{
                        line: 2,
                        code: "print(length)"
                    }]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "7"
                    }]} content = "left"/>
                    <Typography variant = "h6"gutterBottom>Notice that the length of the list is always one more than the greatest index in the list</Typography>
                    <Typography variant = "h6"gutterBottom>Using this method to get the length of a list might seem redundant right now because you can just count the number of items to get the length of a list</Typography>
                    <Typography variant = "h6"gutterBottom>However, if we are altering the list items in anyway or don't know what's inside the list, then using the len() function is all we can do</Typography>
                </Box>

                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>     
                <Typography variant = "h4"gutterBottom>Practice</Typography>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                padding: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#e0e0e0',
                }}>
                <QuestionCard question = {{
                    question: "Which expression is a list with 5 items?",
                }} 
                answers = {{
                    a1: "[1 2 3 4 5]",
                    a2: "[1, 2, 3, 4, 5]",
                    a3: "[0. 1. 2. 3. 4.]",
                    a4: "{1, 2, 3, 4, 5}"
                }}
                answer = {2}
                questionNumber = {5}
                correct = {question5}
                setCorrect = {() => setQuestion5(1)}
                course = 'Lists'
                authUser = {authUser}/>
                <p></p>
                </Box>
                

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>

                <Box sx={{
                padding: '5px',
                display: 'grid', 
                gap : 3,
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#e0e0e0',
                }}> 
                <CodeSnippet lines = {[{
                        line: 1,
                        code: "list = [2, 3, 4, 5, 2, 3, 4]"
                    }]} content = "center"/>
                    <QuestionCard question = {{
                    question: "Which expression returns the length of the list?",
                }} 
                answers = {{
                    a1: "list.len()",
                    a2: "list[7]",
                    a3: "len(list)",
                    a4: "list[len()]"
                }}
                answer = {3}
                questionNumber = {6}
                correct = {question6}
                setCorrect = {() => setQuestion6(1)}
                course = 'Lists'
                authUser = {authUser}
                />
                </Box>

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>

                <LinearProgress color = "success" variant = 'determinate' value = {(((question1 + question2+ question3 + question4 + question5 + question6) /6) * 100)}/>
                { ((question1 + question2+ question3 + question4 + question5 + question6) /6 * 100) === 100 ? 
                <Typography variant = "h5">Finished!</Typography> :  <Typography variant = "h5">Not done yet</Typography>}
        </>
    )
        
};
