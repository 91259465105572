// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword, onAuthStateChanged, browserLocalPersistence } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBS-olFkq9ErCAXMcFvuf6CTcDBAvWmbZU",
  authDomain: "learn-python-433d5.firebaseapp.com",
  databaseURL: "https://learn-python-433d5-default-rtdb.firebaseio.com",
  projectId: "learn-python-433d5",
  storageBucket: "learn-python-433d5.appspot.com",
  messagingSenderId: "908895771939",
  appId: "1:908895771939:web:111b2d1cb9cfbe67888096",
  measurementId: "G-WQ3E46CWHC"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app, {
  persistence: browserLocalPersistence
});
export const db = getDatabase(app);
export const signIn = (email, password) => {
  console.log("Logged in successfully");
  localStorage.clear();
  return signInWithEmailAndPassword(auth, email, password);
};

export const signUp = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
  
};

export const questionsSolved = () => {
  let user = auth.currentUser;
  const userRef = ref(db, 'users/' + user.uid + '/questionsSolved');
  onValue(userRef, (snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.val();
      console.log("exists");
      return null;
    } else {
      console.log("doesn't");
      return null;
    }
  }); 
}

export function getuid() {
  onAuthStateChanged(auth, (user) => {
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        user = auth.currentUser;
        return user.uid;  
        // ...
    } else {
        // User is signed out
        // ...
        return null;
    }
});
}


export function onUserChange(callback) {
  return auth.onAuthStateChanged(user => {
    if (user) {
      callback(true);
      
    } else {
      callback(false);
    }
  });
}

