import {React, useState, useEffect} from 'react';
import Navbar from './components/Navbar';
import LogIn from './components/auth/LogIn';
import Home from './components/pages/Home';
import Courses from './components/pages/Courses';
import { HashRouter, Route, Routes} from "react-router-dom";
import SignUp from './components/auth/SignUp'
import {auth} from "./firebaseConfig";

const App = () => {
    // Create a currentUser state
const [currentUser, setCurrentUser] = useState(null);

// Listen to onAuthStateChanged
useEffect(() => {

    if (auth) {
      auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          setCurrentUser(authUser);
        } else {
          setCurrentUser(null);
        }
      });
    }
  }, []);
    return(

        
        <>
            <Navbar className = ""/>
            <div className = "container">
                
                <Routes>
                    <Route path = "/" element = {<Home />}></Route>
                    <Route path = "/courses/*" element = {<Courses />}></Route>
                    <Route path = "/login" element = {<LogIn />}></Route>
                    <Route path = "/signup" element = {<SignUp />}></Route>
                </Routes>
                
            </div>
            
        </>
    );
}

export default App;