import React, {useState, useEffect} from "react";
import Box from '@mui/material/Box';
import QuestionCard from '../../QuestionCard';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import CodeSnippet from '../../CodeSnippet';
import { db  } from "../../../firebaseConfig";
import {onValue, ref } from "firebase/database";
 

export default function DataTypes(authUser) {
    const [question1, setQuestion1] = useState(0);
    const [question2, setQuestion2] = useState(0);
    const [question3, setQuestion3] = useState(0);
    const [question4, setQuestion4] = useState(0);


    useEffect(() => {
        // console.log(authUser.uid);
        if (localStorage.DataTypesquestion1) {
            setQuestion1(1);
        }
        if (localStorage.DataTypesquestion2) {
            setQuestion2(1);
        }
        if (localStorage.DataTypesquestion3) {
            setQuestion3(1);
        }
        if (localStorage.DataTypesquestion4) {
            setQuestion4(1);
        }
        if (authUser.authUser !== null) {
            onValue(ref(db, `users/${authUser.uid}/questionsSolved/DataTypes/1/solved`), (snapshot) => {
                if (snapshot.exists()) {
                    localStorage.setItem("DataTypesquestion1", 1);
                    setQuestion1(1);
                }});
             onValue(ref(db, `users/${authUser.uid}/questionsSolved/DataTypes/2/solved`), (snapshot) => {
                if (snapshot.exists()) {
                    localStorage.setItem("DataTypesquestion2", 1);
                    setQuestion2(1);
                }});
                onValue(ref(db, `users/${authUser.uid}/questionsSolved/DataTypes/3/solved`), (snapshot) => {
                if (snapshot.exists()) {
                    localStorage.setItem("DataTypesquestion3", 1);
                    setQuestion3(1);
                }});
                onValue(ref(db, `users/${authUser.uid}/questionsSolved/DataTypes/4/solved`), (snapshot) => {
                if (snapshot.exists()) {
                    localStorage.setItem("DataTypesquestion4", 1);
                    setQuestion4(1);
                }});
        } 
    }, []);
    
    return (
        <>
            <Typography variant = "h2">Data Types</Typography>
                <br></br>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4" gutterBottom>Each piece of data in python has its own type</Typography>
                    <Typography variant = "h6" gutterBottom>For example, 1 would have a type of <b>int</b>, short for integer, and "hello" would have a type of <b>str</b>, short for string</Typography>
                    <Typography variant = "h6" >Strings are basically any text that is supposed to be words and not code</Typography>
                    <Typography variant = "h6" gutterBottom>Each data type has its own properties and operations</Typography>
                    <Typography variant = "h6" >For example, ints are treated like numbers, so you can add, subtract, multiply, and divide them</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(1+1)"
                    },{
                        line: 2,
                        code: "print(1-1)"
                    },{
                        line: 3,
                        code: "print(1*2)"
                    },{
                        line: 4,
                        code: "print(6/2)"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "2"
                    },{
                        line: 2,
                        code: "0"
                    },{
                        line: 3,
                        code: "2"
                    },{
                        line: 4,
                        code: "3"
                    },]} content = "left"/>
                    <Typography variant = "h6">There are more int operators in python than addition, subtraction, multiplication, and division; you can search them up if you want</Typography>
                    <Typography variant = "h6" gutterBottom>For strings, you cannot subtract, multiply, or divide them</Typography>
                    <Typography variant = "h6" >However, you can add strings together</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print('hello'+'world')"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "helloworld"
                    },]} content = "left"/>
                    <Typography variant = "h6" >the result is a combination of the two strings, with the first one first and the second one second</Typography>
                    <Typography variant = "h6" >The result is also a string</Typography>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4"gutterBottom>Getting the Type</Typography>
                    <Typography variant = "h6"gutterBottom>we can get the type of a piece of data by using the type() function</Typography>
                    <Typography variant = "h6"gutterBottom>The type() function will give us the type of the piece of data we put into the parentheses</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(type(1))"
                    },{
                        line: 2,
                        code: "print(type('hi'))"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "<class 'int'>"
                    },{
                        line: 2,
                        code: "<class 'str'>"
                    },]} content = "left"/>
                    <Typography variant = "h6" gutterBottom>Class basically means the data type</Typography>
                    <Typography variant = "h6">In this case, we can see that the type of 1 is int, and the type of 'hi' is str</Typography>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Using variables</Typography>
                    <Typography variant = "h6" gutterBottom>Using variables is as simple as using their name where you want the value they represent to be</Typography>
                    <Typography variant = "h6">First, you have to create the variable by providing the name and value and assigning the value to the variable</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "var1 = 1"
                    },{
                        line: 2,
                        code: "var2 = 2"
                    }
                    ,]} content = "center"/>
                    <Typography variant = "h6"gutterBottom>Then, you call the variable name in place of where you want to use the value</Typography>
                    <Typography variant = "h6">The method of using variables is similar to using variables in math</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(var1 + var2)"
                    }]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "3"
                    }]} content = "left"/>
                    <Typography variant = "h6">Note: Variable names are case sensitive</Typography>
                    <Typography variant = "h6"gutterBottom>In this case, var1 was equal to 1, and var2 was equal to 2</Typography>
                    <Typography variant = "h6" gutterBottom>By calling them in the print statement, we print out the sum of var1 and var2</Typography>
                    <Typography variant = "h6">var1 + var2 = 1 + 2 = 3</Typography>
                </Box>
                
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Numbers</Typography>
                    <Typography variant = "h6" gutterBottom>In python, numbers are represented using two data types</Typography>
                    <Typography variant = "h6" gutterBottom>The first one is int, as we saw before, and the second one is float</Typography>
                    <Typography variant = "h6" gutterBottom>float is short for floating point number</Typography>
                    <Typography variant = "h6" gutterBottom>int represents integers- no decimal point-, and float represents numbers with decimals</Typography>
                    <Typography variant = "h6" gutterBottom>Note: complex numbers also have the complex type, but we won't be using them</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(type(1))"
                    },{
                        line: 2,
                        code: "print(type(1.5)"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "<class 'int'>"
                    },{
                        line: 2,
                        code: "<class 'float'>"
                    },]} content = "left"/>
                    <Typography variant = "h6"gutterBottom>As seen before, we can add, subtract, and multiply numbers</Typography>
                    <Typography variant = "h6"gutterBottom>This includes both ints and floats</Typography>
                    <Typography variant = "h6"gutterBottom>We can have an int + int, int + float, or float + float with any of the number operations</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(1+1)"
                    },{
                        line: 2,
                        code: "print(1-1.5)"
                    },{
                        line: 3,
                        code: "print(2.5*2.5)"
                    },{
                        line: 4,
                        code: "print(4.5/1.5)"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "2"
                    },{
                        line: 2,
                        code: "-0.5"
                    },{
                        line: 3,
                        code: "6.25"
                    },{
                        line: 4,
                        code: "3"
                    },]} content = "left"/>
                </Box>

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Strings</Typography>
                    <Typography variant = "h6" gutterBottom>Strings are basically words that aren't code in python</Typography>
                    <Typography variant = "h6" gutterBottom>They are always surrounded by quotation marks- single or double, but it has to be consistent</Typography>
                    <Typography variant = "h6"gutterBottom>For example, 'hi' works, but 'hi" doesn't</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(type('hello'))"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "<class 'str>"
                    },]} content = "left"/>
                    <Typography variant = "h6"gutterBottom>As seen before, we can add strings together</Typography>
                    <Typography variant = "h6"gutterBottom>Spaces will not be automatically included</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print('hi' + ' hello')"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "hi hello"
                    },]} content = "left"/>
                    <Typography variant = "h6"gutterBottom>We can also multiply a string by an int to get that the string repeated that many times</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print('hi ' * 5)"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "hi hi hi hi hi"
                    },]} content = "left"/>
                    <Typography variant = "h6"gutterBottom>To make a newline in the string, we use '/n'</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print('i /n am /n hungry')"
                    },]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "i"
                    },{
                        line: 2,
                        code: "am"
                    },{
                        line: 3,
                        code: "hungry"
                    },]} content = "left"/>
                </Box>

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>
                    <Typography variant = "h4">Type Casting</Typography>
                    <Typography variant = "h6" gutterBottom>We can temporarily change the type of a piece of data by casting it to another</Typography>
                    <Typography variant = "h6"gutterBottom>To cast, the data type we want to change the piece of data to and what type the data is have to be compatable</Typography>
                    <Typography variant = "h6"gutterBottom>Then, we just have to take our piece of data, put it in parentheses, and write the type we want to change the data to in front</Typography>
                    <Typography variant = "h6"gutterBottom><b>newType(</b> + piece of data + <b>)</b></Typography>
                    <Typography variant = "h6"gutterBottom>If we wanted to change 3 into '3', or '3' into 3:</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(type(str(3)))"
                    },{
                        line: 2,
                        code: "print(type(int('3')))"
                    }]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "<class 'str'>"
                    },{
                        line: 2,
                        code: "3, this is an int"
                    }]} content = "left"/>
                    <Typography variant = "h6"gutterBottom>Casting strings to numbers and vice verse only works when they're the same number, but in a different type</Typography>
                    <Typography variant = "h6">Note: Casting 'three' to 3 will not work</Typography>
                    <Typography variant = "h6"gutterBottom>We can also cast floats to int and vice versa and floats to strings and vice versa</Typography>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "print(type(int(3.5)))"
                    },{
                        line: 2,
                        code: "print(type(float(3))"
                    },{
                        line: 3,
                        code: "print(type(str(3.5))"
                    }]} content = "center"/>
                    <CodeSnippet lines = {[{
                        line: 1,
                        code: "3"
                    },{
                        line: 2,
                        code: "3.0"
                    },{
                        line: 3,
                        code: "3.5"
                    }]} content = "left"/>
                    <Typography variant = "h6"gutterBottom>Casting a float to an int will remove the decimal, and casting an int to a float will add the decimal, but not change the number</Typography>
                </Box>

                <Box sx={{
                bgcolor: '#e0e0e0',
                color: '#000000',
                padding: 2,
                borderRadius: 2
                }}>     
                <Typography variant = "h4"gutterBottom>Practice</Typography>
                </Box>
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                padding: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#e0e0e0',
                }}>
                <QuestionCard question = {{
                    question: "what is the type of 3?",
                }} 
                answers = {{
                    a1: "number",
                    a2: "integer",
                    a3: "int",
                    a4: "float"
                }}
                answer = {3}
                questionNumber = {1}
                correct = {question1}
                setCorrect = {() => setQuestion1(1)}
                course = 'DataTypes'
                authUser = {authUser}/>
                <p></p>
                </Box>
                

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>

                <Box sx={{
                padding: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#e0e0e0',
                }}>
                    <QuestionCard question = {{
                    question: "What is the type of '10'?",
                }} 
                answers = {{
                    a1: <>
                    <Box sx = {{
                        bgcolor: "#f0f0f0",
                        padding: 1,
                        borderRadius: 2
                    }}>
                        <Typography>float</Typography>
                    </Box>
                    </>,
                    a2: <>
                    <Box sx = {{
                        bgcolor: "#f0f0f0",
                        padding: 1,
                        borderRadius: 2
                    }}>
                        <Typography>int</Typography>
                    </Box>
                    </>,
                    a3: <>
                    <Box sx = {{
                        bgcolor: "#f0f0f0",
                        padding: 1,
                        borderRadius: 2
                    }}>
                        <Typography>string</Typography>
                    </Box>
                    </>,
                    a4: <>
                    <Box sx = {{
                        bgcolor: "#f0f0f0",
                        padding: 1,
                        borderRadius: 2
                    }}>
                        <Typography>str</Typography>
                    </Box>
                    </>
                }}
                answer = {4}
                questionNumber = {2}
                correct = {question2}
                setCorrect = {() => setQuestion2(1)}
                course = 'DataTypes'
                authUser = {authUser}
                />
                </Box>

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                padding: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#e0e0e0',
                }}>
                <QuestionCard question = {{
                    question: "which expression will not result in an error?",
                }} 
                answers = {{
                    a1: "'3' + 3",
                    a2: "'3' * 3",
                    a3: "'3' * '3'",
                    a4: "'3' * 3.0"
                }}
                answer = {2}
                questionNumber = {3}
                correct = {question3}
                setCorrect = {() => setQuestion3(1)}
                course = 'DataTypes'
                authUser = {authUser}/>
                <p></p>
                </Box>

                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>
                <Box sx={{
                padding: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#e0e0e0',
                }}>
                <QuestionCard question = {{
                    question: "which expression casts 3.0 to a string?",
                }} 
                answers = {{
                    a1: "string(3.0)",
                    a2: "float(string(3.0))",
                    a3: "int(3.0) to string",
                    a4: "str(3.0)"
                }}
                answer = {4}
                questionNumber = {4}
                correct = {question4}
                setCorrect = {() => setQuestion4(4)}
                course = 'DataTypes'
                authUser = {authUser}/>
                <p></p>
                </Box>
                
                <Box sx={{
                bgcolor: '#',
                color: '#000000',
                padding: '5px',
                height: '20px'
                }}></Box>

                <LinearProgress color = "success" variant = 'determinate' value = {(((question1 + question2 + question3 + question4) /4) * 100)}/>
                { (((question1 + question2 + question3 + question4) /4) * 100) === 100 ? 
                <Typography variant = "h5">Finished!</Typography> :  <Typography variant = "h5">Not done yet</Typography>}
        </>
    )
        
};
